import React, { useState, useEffect } from "react";
import searchIcon from "../Assets/searchIcon.svg";
import locationIcon from "../Assets/locationIcon.svg";
import Select from "react-select";
import "./Css/SearchBar.css";
import line from "../Assets/line.svg";
import {
  server_post_data,
  get_search_bar,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleIaphabetnumberChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";
import { retrieveData, storeData } from "../LocalConnection/LocalConnection.js";
const SearchBar = () => {
  let check_custom_city = retrieveData("customer_city");
  let customer_city_add_by_location = retrieveData("customer_city_add_by_location");
  const customer_city =
    check_custom_city === "0" ? "Nagpur" : check_custom_city;
  const customStyles = {
    control: (provided) => ({
      ...provided,
      color: "var(--text-grey)",
      fontSize: "14px",
      border: "none",
      boxShadow: "none",
      minHeight: "initial",
      width: "max-content",
      height: "38px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "1",
      cursor: "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      borderRadius: "8px",
      width: "max-content",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "var(--primary-color)"
        : state.isFocused
        ? "#f2f2f2"
        : "white",
      color: state.isSelected ? "white" : "black",
      padding: "8px 30px",
      cursor: "pointer",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0",
      width: "fit-content",
      border: "none",
      borderRadius: "8px",
      color: "var(--text-grey)",
      fontSize: "15px",
    }),
  };

  function getDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Radius of the Earth in km
    const dLat = ((lat2 - lat1) * Math.PI) / 180;
    const dLon = ((lon2 - lon1) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
        Math.cos((lat2 * Math.PI) / 180) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  }

  const [selectedLocation, setSelectedLocation] = useState(customer_city);
  const [locations, seLocation] = useState([]);
  const [isSearchActive, setisSearchActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [newproducts, setnewproducts] = useState([]);
  const [productpath, setproductpath] = useState("");
  const [SEOloop, setSEOloop] = useState([]);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/venue/venue_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  useEffect(() => {
    get_all_search_data(0, 0, 0);
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          get_all_search_data(
            0,
            position.coords.latitude,
            position.coords.longitude
          );
        },
        (err) => {
          console.log("Error getting location: " + err.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by your browser");
    }
  }, []);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newTimeout = setTimeout(() => {
      if (searchText.trim().length > 2) {
        get_all_search_data(1);
      } else {
        setisSearchActive(false);
      }
    }, 500);
    setSearchTimeout(newTimeout);
    return () => {
      if (newTimeout) {
        clearTimeout(newTimeout);
      }
    };
  }, [searchText]);

  const get_all_search_data = async (flag, userLat, userLon) => {
    let Data = new FormData();
    let minDistance = Infinity;
    let nearest = null;
    Data.append("search_data", searchText);
    Data.append("city_name", selectedLocation);
    Data.append("flag", flag);
    await server_post_data(get_search_bar, Data)
      .then(async (Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSEOloop(Response.data.message.seo_loop);
          if (flag === 0) {
            seLocation(Response.data.message.location_loop);
            if (userLat !== 0 && customer_city_add_by_location==="0") {
              Response.data.message.location_loop.forEach((city) => {
                const distance = getDistance(
                  userLat,
                  userLon,
                  city.lat,
                  city.lng
                );

                if (distance < minDistance) {
                  minDistance = distance;
                  nearest = { ...city, distance };
                }
              });
              storeData("customer_city", nearest.city);
              storeData("customer_city_add_by_location", nearest.city);
              setSelectedLocation(nearest.city);
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }

            console.log(Response.data.message.location_loop);
          } else {
            setnewproducts(Response.data.message.search_fields);
            setproductpath(APL_LINK + Response.data.message.data_admin_image);
            setisSearchActive(true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("Something Went Wrong");
      });
  };

  const handleLocationChange = (selectedOption) => {
    storeData("customer_city", selectedOption.city);
    setSelectedLocation(selectedOption.city);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <>
      <div className="searchBar_wrapper">
        <div className="searchBar_container">
          <div className="locationSection_searchbar">
            <img src={locationIcon} alt="loaction" />
            <Select
              id="selectLocation"
              options={locations}
              value={locations.find(
                (location) => location.value === customer_city
              )}
              onChange={handleLocationChange}
              placeholder="Location"
              styles={customStyles}
            />

            <img src={line} alt="line" />
          </div>
          <div className="seachVenue_section_searchbar">
            <img src={searchIcon} alt="search icon" />
            <input
              className="form-control"
              placeholder="Search for Venue, events"
              aria-label="Search for Venue, events"
              type="text"
              maxLength={30}
              onInput={handleIaphabetnumberChange}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <div className="drop_down_searchBar">
          {isSearchActive && (
            <div className="searchItems">
              {newproducts.map((item, index) => {
                return (
                  <Link
                    onClick={() =>
                      handleLinkClick(
                        match_and_return_seo_link(item.primary_id)
                      )
                    }
                    key={index}
                  >
                    <div className="itemSearch">
                      <img
                        src={`${productpath}${item.venue_images}`}
                        alt={item.venue_name}
                      />
                      <div className="search_result_text">
                        <h6>{item.venue_name}</h6>
                        <p>{item.map_address}</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {newproducts && newproducts.length === 0 && (
                <div className="itemSearch center_justify">
                  <div>
                    <h6>No Data Found</h6>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchBar;
